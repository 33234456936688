import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Hero from '../components/Hero';
// import ProcessSection from '../components/ProcessSection';
// import ShapeSection from '../components/ShapeSection';
import MaterialsSection from '../components/MaterialsSection';
import SEO from '../components/SEO';
import Gallery from '@browniebroke/gatsby-image-gallery';
import { motion } from 'framer-motion';

const StyledContent = styled.section`
  width: 95%;
  @media (min-width: 768px) {
    width: 90%;
  }
  max-width: 1280px;
  margin: 0 auto;
  .gallery-section {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 75px;
  }
`;

const GalleryGridWrapper = styled.div`
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
  width: min-content;
  max-width: 768px;
  width: 100%;
  > div {
    justify-content: ${(props) => (props.images > 3 ? 'flex-start' : 'center')};
    margin-right: 0;
    margin-left: 0;
  }
`;

const MotionGalleryGridWrapper = motion(GalleryGridWrapper);

const itemVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
      delay: 0.2,
    },
  },
};
const galleryVariants = {
  offscreen: {
    opacity: 0,
  },
  onscreen: {
    opacity: 1,
    transition: {
      delay: 0.1,
    },
  },
};

export default function FabricationPage({ data, location }) {
  const images = data.sanityFabricationPage.galleryImages.map(
    (image) => image.asset
  );
  return (
    <>
      <SEO title={'Custom Sheet Metal Fabrication'} />
      <Hero
        bg={data.sanityFabricationPage}
        heading={data.sanityFabricationPage.name}
        subText={data.sanityFabricationPage.description}
        bgAlt={data.sanityFabricationPage.headerImage.altText}
        location={location}
      />
      <StyledContent>
        {/* <ProcessSection processCard={data.sanityFabricationPage.processCard} />
        <ShapeSection shapeData={data.sanityFabricationPage.shapes} /> */}
        <MaterialsSection materialData={data.sanityFabricationPage.materials} />
        <motion.div
          variants={galleryVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.25 }}
          className="gallery-section"
        >
          <motion.h2 variants={itemVariants}>Gallery</motion.h2>
          <MotionGalleryGridWrapper
            variants={itemVariants}
            images={images.length}
          >
            <Gallery images={images} />
          </MotionGalleryGridWrapper>
        </motion.div>
      </StyledContent>
    </>
  );
}

export const { data } = graphql`
  query FabricationPageQuery {
    sanityFabricationPage(id: { eq: "-0ba0f2f0-b1bf-5b99-89e6-a0c04193abdb" }) {
      name
      galleryImages {
        asset {
          thumb: gatsbyImageData(width: 195, height: 195)
          full: gatsbyImageData(layout: FULL_WIDTH)
        }
        altText
      }
      headerImage {
        asset {
          gatsbyImageData(formats: [AUTO, WEBP, AVIF], aspectRatio: 1.7778)
        }
        altText
      }
      description
      materials {
        fabricationMaterialImage {
          asset {
            gatsbyImageData
          }
        }
        fabricationMaterialName
      }
    }
  }
`;

// shapes {
//         shapeName
//         shapeIcon {
//           asset {
//             gatsbyImageData
//           }
//         }
//       }
//
//       processCard {
//         logoImage {
//           asset {
//             gatsbyImageData(height: 70, formats: [AUTO, WEBP, AVIF])
//           }
//         }
//         description
//         name
//       }
