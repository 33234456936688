import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import MaterialCard from './MaterialCard';

const StyledCardSection = styled.section`
  margin: 0 auto;
  padding-top: 50px;
  text-align: center;
  h2 {
    padding-bottom: 40px;
  }
  .card-grid {
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
    }
    gap: 42px;
    text-align: center;
  }
`;

const sectionVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
      delay: 0.2,
    },
  },
};

const MotionCardSection = motion(StyledCardSection);

export default function MaterialSection({ materialData }) {
  const cards = materialData.map((card) => <MaterialCard card={card} />);
  return (
    <MotionCardSection
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.1 }}
    >
      <motion.h2 variants={sectionVariants}>Our Materials</motion.h2>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.1 }}
        className="card-grid"
      >
        {cards}
      </motion.div>
    </MotionCardSection>
  );
}
