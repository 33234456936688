import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const StyledCard = styled.div`
  position: relative;
  margin: 0 auto;
  .card-box {
    min-width: 200px;
    max-width: 283px;
    box-shadow: 0.1em 0.1em 0.5em rgba(60, 60, 60, 65%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    background-color: var(--midgrey);
    .card-text {
      width: 200px;
      color: var(--white);
      padding-bottom: 16px;
    }
  }
`;

const MotionCard = motion(StyledCard);

const cardVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
      delay: 0.3,
    },
  },
};

export default function MaterialCard({ card }) {
  const image = getImage(card.fabricationMaterialImage.asset.gatsbyImageData);
  return (
    <MotionCard variants={cardVariants}>
      <div className="card-box">
        <GatsbyImage image={image} alt={card.fabricationMaterialName} />
        <p className="card-text">{card.fabricationMaterialName}</p>
      </div>
    </MotionCard>
  );
}
